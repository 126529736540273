import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const FAQ = ({ data }) => {
  const FAQData = data.allDatoCmsFaq.edges
  const [FAQState, setFAQState] = useState(() => [
    true,
    ...new Array(13).fill(false),
  ])

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/faq")
    }
  }, [])

  function FAQClick(index) {
    setFAQState(previousState => {
      return previousState.map((element, i) => (i === index ? !element : false))
    })
  }

  return (
    <React.Fragment>
      <PageTitle title={"FAQ"} />
      <Layout>
        <div className="gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>FAQ</a>
              </li>
            </ul>
          </div>
          <div className="asked-question">
            <h1>
              Frequently Asked Questions <span>(FAQ)</span>
            </h1>
            <div className="asked-question__block">
              <div className="asked-question__accordion">
                {FAQData.map((faq, index) => {
                  faq = faq.node
                  return (
                    <div
                      className={`asked-question__accordion--box${
                        FAQState[index] ? " active" : ""
                      }`}
                      key={index}
                    >
                      <div
                        className="asked-question__accordion--name js-accordion__name"
                        onClick={() => FAQClick(index)}
                      >
                        {faq.question}
                        {FAQState[index] ? (
                          <svg
                            width="43"
                            height="44"
                            viewBox="0 0 43 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: 43, minWidth: 43, maxWidth: 43 }}
                          >
                            <path
                              d="M10.4237 32.4237L31.6369 11.2105M10.9723 11.0277L32.1855 32.2409"
                              stroke="#0B67F0"
                              strokeWidth="2"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: 30, minWidth: 30, maxWidth: 30 }}
                          >
                            <path
                              d="M0 14.7414H30M15.5172 0L15.5172 30"
                              stroke="#0B67F0"
                              strokeWidth="2"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="asked-question__accordion--body">
                        <div
                          className="asked-question__accordion--body--content"
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Wollen Sie mehr über Cyber Trust Austria erfahren?
                    Kontaktieren Sie uns.
                  </b>
                </h2>
              </div>
              <div>
                <div
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   marginTop: 25,
                // }}
                >
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/30min"
                    target="_blank"
                  >
                    Zur kostenlosen Beratung
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default FAQ

export const query = graphql`
  {
    allDatoCmsFaq(
      filter: { locale: { eq: "de-DE" } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`
